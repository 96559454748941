import React from 'react';
import './LinkForm.css';
import COUNTRIES from './countries.js';

class LinkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'countries': [
        {'country': 'US', 'link': ''},
        {'country': 'CA', 'link': ''}
      ],
      'defaultCountry': 0
    };
  }

  handleSelectChange(event, index) {
    let countries = this.state.countries.slice();
    countries[index].country = event.target.value;
    this.setState({ 'countries': countries });
  }

  handleLinkChange(event, index) {
    let countries = this.state.countries.slice();
    countries[index].link = event.target.value;
    this.setState({ 'countries': countries });
  }

  addButtonClick(event) {
    event.preventDefault();
    let countries = this.state.countries.slice();
    countries.push({ 'country': 'US', 'link': ''});
    this.setState({ 'countries': countries });
  }

  removeButtonClick(event, index) {
    event.preventDefault();
    let countries = this.state.countries.slice();
    countries.splice(index, 1);

    let defaultCountry = this.state.defaultCountry;

    if (this.state.defaultCountry === index) {
      defaultCountry = 0;
    } else if (defaultCountry > index) {
      defaultCountry -= 1;
    }

    this.setState({ 'countries': countries, 'defaultCountry': defaultCountry});
  }

  handleDefaultCountryChange(event, index) {
    this.setState({ 'defaultCountry': index });
  }

  validateResponse() {
    // Ensure there aren't two links for any given country (wouldn't break server
    // if there was, but it isn't good UX)
    const countries = this.state.countries.map(country => country.country);
    const dupCountries = countries.filter((v,i,a) => a.indexOf(v) !== i);
    if (dupCountries.length > 0) {
      return 'Duplicate countries!';
    }

    return null;
  }

  handleSubmitClick(event) {
    event.preventDefault();
    const linkSummary = {
      'errorMessage': this.validateResponse(),
      'countries': this.state.countries,
      'defaultCountry': this.state.countries[this.state.defaultCountry].country
    };
    this.props.submitCallback(linkSummary);
  }

  render() {
    const options = Object.keys(COUNTRIES).map((key) =>
      <option value={key}>{COUNTRIES[key]}</option>
    );
    const formItems = this.state.countries.map((country, i) =>
      <div className="link-form-item">
        <input type="radio" name="default-country"
        value={i} checked={this.state.defaultCountry === i}
        onChange={(e) => this.handleDefaultCountryChange(e, i)}/>
        <select value={country.country}
        onChange={(e) => this.handleSelectChange(e, i)}
        className="country-select custom-select">{options}</select>
        <input type="text" className="form-control" value={country.link} onChange={(e) => this.handleLinkChange(e, i)} />
        { this.state.countries.length > 1 && <button className="btn btn-danger" onClick={(e) => this.removeButtonClick(e, i)}>-</button> }
      </div>
    );
    return (
      <div className="LinkForm">
        <form>
          <div className="label-wrapper">
            <label>Default</label>
            <label>Country</label>
            <label className="space-fill-label">Link</label>
          </div>
          { formItems }
          <button className="btn btn-primary" onClick={(e) => this.addButtonClick(e)}>+</button><br />
          <input className="btn btn-success" type="submit" onClick={(e) => this.handleSubmitClick(e)} value="Get Link" />
        </form>
      </div>
    );
  }
}

export default LinkForm;
