import React from 'react';
import './App.css';
import LinkForm from './LinkForm.js';
import axios from 'axios';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '',
      errorMessage: ''
    };
  }

  formSubmitCallback(result) {
    if (!result.errorMessage) {
      axios.post('/api/create.php', result)
      .then((response) => this.setState({'link': window.location.origin + '/go/' + response.data.slug, 'errorMessage': ''}))
      .catch((error) => {
        if (error.response) {
          this.setState({ 'errorMessage': error.response.status + ': ' + error.response.data.status});
        } else {
          this.setState({ 'errorMessage': error.errorMessage });
        }

        this.setState({ 'link': '' });
      });
    } else {
      this.setState({ 'errorMessage': result.errorMessage });
    }
  }

  render() {
    return (
      <div className="App">
        <LinkForm submitCallback={(r) => this.formSubmitCallback(r)}/>
        { this.state.link.length > 0 &&
          <div className="alert alert-success globaly-alert">
            <h3>Your link!</h3>
            <a href={ this.state.link }>{ this.state.link }</a>
          </div> }
        { this.state.errorMessage.length > 0 &&
          <div className="alert alert-danger globaly-alert">
            <h3>Error!</h3>
            <p>{ this.state.errorMessage }</p>
          </div> }
      </div>
    );
  }
}

export default App;
